<template>
    <v-container fluid class="mb-0 pb-0">
        <h2 class="grey--text text--darken-2 ma-3" v-if="!backendForm">
            <v-icon class="grey--text text--darken-4 br-1 pb-1">mdi-form-select</v-icon> {{$router.currentRoute.name}}
            <slot name="header-suffix"></slot>
        </h2>
        <v-form
            ref="form"
            v-if="!checkForm"
            v-model="valid"
            lazy-validation
            :disabled="!editable"
        >
            <div
                class="pa-0"
                v-for="(fieldGroup, index) in getEditFields()" :key="index"
            >
                <v-card
                    class="pa-2 mb-2"
                >
                    <v-card-title
                        class="pa-0 pl-2 grey--text text--darken-3"
                        v-if="fieldGroup.groupTitle !== ''"
                    >
                        {{fieldGroup.groupTitle}}
                    </v-card-title>

                    <div v-for="(value, key) in fieldGroup.fields" :key="key" class="mt-1">

                        <v-row v-if="value.meta.interface !== 'edit-buttons' && 
                            value.meta.group !== 'wunschposition' && 
                            value.meta.group !== 'festposition' && 
                            value.meta.group !== 'provinz_farbe' && 
                            (value.meta.interface !== 'group-raw' || value.meta.field === 'wunschposition' || value.meta.field === 'festposition' || value.meta.field === 'provinz_farbe')">
                            <!-------------------- Table Key -------------------->
                            <v-col
                                cols="12"
                                sm="3"
                                v-if="value.meta.interface !== 'presentation-notice'"
                                :align="[!isMobile() ? 'right' : 'left']"
                                class="pr-0 col-3 grey--text text--darken-3"
                                :class="[!isMobile() ? 'pt-6' : 'pb-0'] "
                            >   
                                <v-tooltip bottom :disabled="!value.meta.note || value.meta.note === ''">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div 
                                            v-bind="attrs" v-on="on"
                                            v-if="value.meta.interface !== 'display-images'"
                                        >
                                            <span :class="[fieldIsDisabled(false, key)?'grey--text':'']">
                                                <b>{{getTranslation(key)}}</b><span  :class="[fieldIsDisabled(false, key)?'grey--text':'primary--text']" v-if="value.meta.required"> *</span>
                                                <span
                                                    v-if="value.meta.note"
                                                >
                                                    <v-icon small 
                                                        :class="[fieldIsDisabled(false, key)?'grey--text':'primary--text']"
                                                        dark
                                                    >
                                                        mdi-information
                                                    </v-icon>
                                                </span>
                                            </span>
                                        </div>
                                    </template>
                                    <span v-html="value.meta.note"></span>
                                </v-tooltip>
                            </v-col>
                            <!-------------------- Table Interface -------------------->
                            <v-col
                                :class="[isMobile() ? 'pt-0' : '']"
                                :cols="$vuetify.breakpoint.xsOnly ? '12' : false"
                            >
                                <!-- {{peopleManager.editedId}} -->

                                <!-- {{value.meta.interface}} - {{value.type}} -->
                                <!-- {{peopleManager.editData[key]}} -->
                                <transition name="slide-fade" appear>
                                    <!-- Position Wish -->

                                    <interface-depot-transactions
                                        v-if="value.meta.interface === 'interface-depot-transaction'"
                                        :meta="value.meta"
                                        v-model="peopleManager.editData[key]"
                                    />
                                    <v-text-field
                                        v-else-if="value.meta.field === 'confirmation_token'"
                                        dense solo hide-details
                                        v-model="peopleManager.editData[key]"
                                        :readonly="fieldIsDisabled(value.meta.readonly, key)"
                                        :append-icon="'mdi-lock-reset'"
                                        @click:append="regenerateToken(key)"
                                    >

                                    </v-text-field>
                                    <interface-position-wish-new
                                        v-else-if="value.meta.field === 'mitarbeiter_wunsch'"
                                        v-model="peopleManager.editData[key]"
                                        :employee-wish="true"
                                        :year="year"
                                        :backendForm="backendForm"
                                    />
                                    <interface-position-wish-new
                                        v-else-if="value.meta.field === 'mitarbeiter_position'"
                                        v-model="peopleManager.editData[key]"
                                        :final-position="true"
                                        :year="year"
                                        :heading="false"
                                        :backendForm="backendForm"
                                    />
                                    <interface-position-wish-new
                                        v-else-if="value.meta.field === 'provinz'"
                                        v-model="peopleManager.editData[key]"
                                        :group-color="true"
                                        :year="year"
                                        :heading="false"
                                        :backendForm="backendForm"
                                    />
                                    <interface-status-multiple
                                        v-else-if="value.meta.display === 'status-multiple'"
                                        v-model="peopleManager.editData[key]"
                                        :items="value.meta.display_options.status"
                                        :disabled="fieldIsDisabled(value.meta.readonly, key)"
                                    />
                                    <interface-position-wish 
                                        v-else-if="value.meta.field === 'wunschposition'"
                                        :peopleManager="peopleManager"
                                        :e1_wish_1="getFieldByName('e1_wunschposition_1')"
                                        :e1_wish_2="getFieldByName('e1_wunschposition_2')"
                                        :e2_wish_1="getFieldByName('e2_wunschposition_1')"
                                        :e2_wish_2="getFieldByName('e2_wunschposition_2')"
                                        :e3_wish_1="getFieldByName('e3_wunschposition_1')"
                                        :e3_wish_2="getFieldByName('e3_wunschposition_2')"
                                    />

                                    <interface-position-wish 
                                        v-else-if="value.meta.field === 'festposition'"
                                        :peopleManager="peopleManager"
                                        :heading="false"
                                        :e1_wish_1="getFieldByName('e1_festposition')"
                                        :e2_wish_1="getFieldByName('e2_festposition')"
                                        :e3_wish_1="getFieldByName('e3_festposition')"
                                    />

                                    <interface-position-wish 
                                        v-else-if="value.meta.field === 'provinz_farbe' && value.meta.interface === 'group-raw'"
                                        :peopleManager="peopleManager"
                                        :heading="false"
                                        :e1_wish_1="getFieldByName('e1_provinz_farbe')"
                                        :e2_wish_1="getFieldByName('e2_provinz_farbe')"
                                        :e3_wish_1="getFieldByName('e3_provinz_farbe')"
                                    />
                                    
                                    <!-- Camp Display -->
                                    <display-camps
                                        v-else-if="value.meta.field === 'freizeit_termine'"
                                        :color="'primary'"
                                        :year="year"
                                    ></display-camps>

                                    <!-- Images Display -->
                                    <display-images
                                        :collection="peopleManager.collectionWithYear"
                                        v-else-if="value.meta.interface === 'display-images'"
                                        :images="value.meta.options.images"
                                    />


                                    <!-- Info Text -->
                                    <v-alert 
                                        v-else-if="value.meta.interface === 'presentation-notice'"
                                        text
                                        :color="value.meta.options.color || 'normal'"
                                        icon="mdi-information"
                                        border="left"
                                    >
                                        <span v-html="value.meta.options.text"></span>
                                    </v-alert>

                                    <!-- Camp Selection Interface -->
                                    <interface-camp-selection
                                        ref="campSelection"
                                        v-else-if="value.meta.field === 'freizeit' && !backendForm"
                                        v-model="peopleManager.editData[key]"
                                        :rules="getRule(value.meta)"
                                        :formData="peopleManager.editData"
                                        :year="year"
                                    />
                                    <!-- Combobox Interface -->
                                    <interface-array
                                        v-else-if="value.meta.interface === 'tags'"
                                        v-model="peopleManager.editData[key]"
                                        :disabled="!editable || fieldIsDisabled(value.meta.readonly, key)"
                                    />
                                    <!-- Combobox replaced by new array interface -->
                                    <!-- <interface-combobox
                                        v-else-if="value.meta.interface === 'tags'"
                                        v-model="peopleManager.editData[key]"
                                    /> -->

                                    <!-- Checkbox Interface -->
                                    <v-checkbox
                                        :false-value="false"
                                        :true-value="true"
                                        v-else-if="value.meta.interface === 'boolean'"
                                        v-model="peopleManager.editData[key]"
                                        hide-details="auto"
                                        :rules="getRule(value.meta)"
                                        class="mt-1"
                                        :label="value.meta.options ? value.meta.options.label : ''"
                                        :disabled="fieldIsDisabled(value.meta.readonly, key)"
                                    />
                                    <!-- checkbox multiple interface -->
                                    <interface-multiple-checkbox
                                        v-else-if="value.meta.interface === 'select-multiple-checkbox' && value.meta.options"
                                        v-model="peopleManager.editData[key]"
                                        :items="value.meta.options.choices"
                                        hide-details="auto"
                                        :disabled="fieldIsDisabled(value.meta.readonly, key)"
                                    />

                                    <!-- select dropdown interface -->
                                    <v-select
                                        v-else-if="value.meta.interface === 'select-dropdown' && value.meta.options"
                                        solo
                                        hide-details="auto"
                                        dense
                                        :items="value.meta.options.choices"
                                        v-model="peopleManager.editData[key]"
                                        :rules="getRule(value.meta)"
                                        :disabled="fieldIsDisabled(value.meta.readonly, key)"
                                    />
                                    <!-- select radio interface -->
                                    <v-radio-group
                                        v-else-if="value.meta.interface === 'select-radio' && value.meta.options"
                                        v-model="peopleManager.editData[key]"
                                        row
                                        hide-details="auto"
                                        class="mt-0"
                                        :rules="getRule(value.meta)"
                                        :disabled="fieldIsDisabled(value.meta.readonly, key)"
                                    >
                                        <v-radio v-for="(item, key) in value.meta.options.choices" :key="key"
                                            :label="item.text"
                                            :value="item.value"
                                        >
                                        </v-radio>

                                    </v-radio-group>

                                    <!-- select dropdown interface -->
                                    <v-select
                                        v-else-if="value.meta.interface === 'select-multiple-dropdown' && value.meta.options"
                                        solo
                                        hide-details="auto"
                                        dense
                                        :items="value.meta.options.choices"
                                        v-model="peopleManager.editData[key]"
                                        :rules="getRule(value.meta)"
                                        multiple
                                        :disabled="fieldIsDisabled(value.meta.readonly, key)"
                                    />

                                    <!-- text field interface -->
                                    <v-textarea
                                        v-else-if="value.meta.interface === 'input-multiline'"
                                        solo
                                        v-model="peopleManager.editData[key]"
                                        hide-details="auto"
                                        :rules="getRule(value.meta)"
                                        :disabled="fieldIsDisabled(value.meta.readonly, key)"
                                    />

                                    <!-- date field interface -->
                                    <interface-age
                                        v-else-if="(value.type === 'dateTime' && key==='geburtstag') || value.type === 'date'"
                                        v-model="peopleManager.editData[key]"
                                        :rules="getRule(value.meta)"
                                        :disabled="fieldIsDisabled(value.meta.readonly, key) || !editable"
                                        :ageLimiter="value.meta.field==='geburtstag'?0:-10"
                                    />
                                    <v-text-field
                                        v-else-if="value.type === 'dateTime'"
                                        dense
                                        solo
                                        type="datetime"
                                        v-model="peopleManager.editData[key]"
                                        hide-details="auto"
                                        :rules="getRule(value.meta)"
                                        :disabled="fieldIsDisabled(value.meta.readonly, key)"
                                    />
                                    <!-- number input interface -->
                                    <v-text-field
                                        v-else-if="value.meta.interface==='input' && value.type ==='integer'"
                                        solo
                                        dense
                                        hide-details="auto"

                                        :min="0"
                                        :rules="getRule(value.meta)"
                                        v-model="peopleManager.editData[key]"
                                        type="number"
                                        :disabled="fieldIsDisabled(value.meta.readonly, key)"
                                    />
                                    
                                    <interface-documents
                                        :id="parseInt(peopleManager.editedId)"
                                        :collection="peopleManager.collectionWithYear"
                                        :fieldName="value.meta.field"
                                        :meta="value.meta"
                                        v-model="peopleManager.editData[key]"
                                        v-else-if="value.meta.interface ==='interface-documents'"
                                    />
                                    <interface-checkbox
                                        v-else-if="value.meta.interface === 'interface-checkbox'"
                                        v-model="peopleManager.editData[key]"
                                        :rules="getRule(value.meta)"
                                        :disabled="fieldIsDisabled(value.meta.readonly, key)"
                                        :options="value.meta.options"
                                    />
                                    <!-- normal input interface -->
                                    <v-text-field
                                        v-else
                                        dense
                                        solo
                                        v-model="peopleManager.editData[key]"
                                        hide-details="auto"
                                        :rules="getRule(value.meta)"
                                        :disabled="fieldIsDisabled(value.meta.readonly, key)"
                                    />
                                </transition>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </div>

            <v-row v-if="!valid && !backendForm">
                <v-col>
                    <v-alert dense class="mb-0" border="left" color="error" icon="mdi-alert" text>
                        Das Formular ist noch nicht vollständig oder korrekt ausgefühlt. Bitte prüfe deine Eingaben noch einmal.
                    </v-alert>
                </v-col>
            </v-row>
            <v-divider class="mt-4 mb-4"/>
            <v-row class="mb-2">
                <v-spacer/>
                <div v-if="backendForm">
                    <v-btn small class="mr-2 mt-2 mb-2 error" @click="onCancel();"><v-icon>mdi-close-thick</v-icon>Abrrechen</v-btn>
                    <v-btn :disabled="!editable" small class="mr-2 mt-2 mb-2 primary" @click="validate"><v-icon>mdi-check-bold</v-icon> Speichern</v-btn>
                </div>
                <div v-else>
                    <v-btn 
                        small 
                        class="mr-2 mt-2 mb-2 primary" @click="validate"
                    >
                        <v-icon>mdi-chevron-right</v-icon> Weiter 
                    </v-btn>
                </div>
                <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
            </v-row>
        </v-form>
        <!-- Form Check -->
        <v-container fluid v-else>
            <v-row>
                <v-col cols="12" class="pb-0">
                    <v-alert border="left" icon="mdi-help-circle-outline" full-width text color="warning">
                        Bitte nimm dir die Zeit und prüfe ob alle Angaben korrekt ausgefüllt sind.
                    </v-alert>
                </v-col>
                <v-col cols="12">
                    <!-- Registration Summary -->
                    <display-registration-summary 
                        :peopleFormEditor="peopleFormEditor" 
                        :peopleManager="peopleManager"
                        ref="registrationSummary"
                    />
                    <!-- Checkout Display -->
                    <display-checkout
                        v-if="showCheckout" 
                        ref="checkoutDisplay"
                        :tasse-preis="peopleManager.camp.tasse_preis"
                        :tshirt-preis="peopleManager.camp.tshirt_preis"
                    />
                </v-col>
            </v-row>
            <v-divider class="mt-4 mb-4"/>
            <v-row
            > 
                <v-spacer/>
                
                <v-btn 
                    small
                    class="ma-2 primary" @click="correctForm()"
                >
                    <v-icon>mdi-pencil</v-icon>
                    Korrigieren
                </v-btn>
                <v-btn 
                    small
                    class="ma-2 success" @click="sendForm()"
                >
                    <v-icon v-if="!peopleManager.waitingForServerResponse">mdi-send</v-icon>
                    <v-progress-circular
                        v-else
                        size=24
                        indeterminate
                    ></v-progress-circular>
                    Abschicken
                </v-btn>
                <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
            </v-row>

        </v-container>
        
    </v-container>
</template>

<script>
import InterfaceCampSelection from './interfaces/InterfaceCampSelection.vue'
import InterfaceAge from './interfaces/InterfaceAge.vue'
import InterfaceMultipleCheckbox from './interfaces/InterfaceMultipleCheckbox.vue'
// import InterfaceCombobox from './interfaces/InterfaceCombobox.vue'
import InterfacePositionWish from './interfaces/InterfacePositionWish.vue'
import InterfaceStatusMultiple from './interfaces/InterfaceStatusMultiple.vue'
import DisplayCamps from './displays/DisplayCamps.vue'
import DisplayImages from './displays/DisplayImages.vue'
import DisplayCheckout from './displays/DisplayCheckout.vue'
import DisplayRegistrationSummary from './displays/DisplayRegistrationSummary.vue'
import InterfacePositionWishNew from './interfaces/InterfacePositionWishNew.vue'
import InterfaceArray from './interfaces/InterfaceArray.vue'
import InterfaceDocuments from './interfaces/InterfaceDocuments.vue'
import InterfaceCheckbox from './interfaces/InterfaceCheckbox.vue'
import InterfaceDepotTransactions from './interfaces/InterfaceDepotTransactions.vue'

export default {
    name:"PeopleFormEditor",
    components:{
        InterfaceCheckbox,
        InterfaceCampSelection,
        InterfaceAge,
        InterfaceMultipleCheckbox,
        InterfaceDepotTransactions,
        // InterfaceCombobox,
        DisplayCamps,
        DisplayImages,
        InterfaceStatusMultiple,
        DisplayCheckout,
        DisplayRegistrationSummary,
        InterfacePositionWish,
        InterfacePositionWishNew,
        InterfaceArray,
        InterfaceDocuments
    },
    props:{
        peopleManager:Object,
        year:Number,
        onSave:{type:Function, default:()=>{}},
        onCancel:{type:Function, default:()=>{}},
        disabled:{type:Boolean, default:false},
        backendForm:{type:Boolean, default:true},
        dataValidation:{type:Boolean, default:true},
        showCheckout:{type:Boolean, default:true},
        editable:{type:Boolean, default:true},
    },
    data(){
        return{
            peopleFormEditor:this,
            title:"",
            valid:true,
            checkForm:false
        }
    },
    methods:{
        fieldIsDisabled(readOnly, fieldName){
            if(readOnly){
                return true;
            }
            return !this.hasWritePermission(fieldName);
        },
        hasWritePermission(fieldName){
            return this.peopleManager.writePermissionFields.indexOf("*") !== -1 || this.peopleManager.writePermissionFields.indexOf(fieldName) !== -1
        },
        regenerateToken(key){
            let newToken = "";
            let rand = function() {
                return Math.random().toString(36).substr(2); // remove `0.`
            };
            let token = function() {
                return rand() + rand(); // to make it longer
            };
            newToken = token();
            this.peopleManager.editData[key] = newToken;
        },
        isMobile(){
            return this.$vuetify.breakpoint.xsOnly;
        },
        getRule(meta){
            var rules = [];
            if(meta.required){
                rules.push(v => !!v || "Feld ist erforderlich.");
            }
            if(meta.field === "email"){
                rules.push(v => /.+@.+\..+/.test(v) || 'Bitte korrekte Email-Adresse eintragen.',)
            }
            return rules;
        },
        validate(){
            if(this.dataValidation){
                this.$refs["form"].validate();
                if(this.$refs.campSelection){
                    this.$refs.campSelection[0].validateInput();
                }
            }
            this.$nextTick(()=>{
                if(this.valid || !this.dataValidation){
                    if(!this.backendForm){
                        this.checkForm = true;
                        window.scrollTo(0,0);
                        if(this.showCheckout){
                            this.$nextTick(()=>{
                                this.$refs["checkoutDisplay"].setupCheckout(this.peopleManager.editData, this.peopleManager.collectionWithYear)
                            })
                        }
                    }else{
                        this.sendForm();
                    }
                }
            })
        },
        correctForm(){
            this.checkForm = false;
            window.scrollTo(0,0);
        },
        sendForm(){
            this.peopleManager.saveEdit(); 
            this.onSave();
        },
        getLanguage(){
            return navigator.language || navigator.userLanguage;
        },
        getTranslation(key){
            for(let field of this.peopleManager.fieldData){
                if (field.field === key){
                    if(field.meta.translations !== null){
                        for(let translation of field.meta.translations){
                            if(translation.language === this.getLanguage()){
                                return translation.translation;
                            }
                        }
                    }
                }
            }
            return this.pascalize(key);
        },
        pascalize(str) {
            str = str.replaceAll("_"," ");
            return str.replace(/(\w)(\w*)/g,function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});
        },
        checkCondition(value1, value2, rule){
            if(rule == "_nempty"){
                return value1 != null

            }else if(rule == "_empty"){
                return value1 == null

            }else if(rule == "_eq"){
                if (Array.isArray(value1)){
                    return value1.includes(value2);
                }
                return value1 == value2
            }else if(rule == "_neq"){
                if (Array.isArray(value1)){
                    return !value1.includes(value2);
                }
                return value1 != value2
            }else if(rule == "_in"){
                return value2.includes(value1);
            }else if(rule == "_null"){
                return value1 == null
            }else if(rule == "_nnull"){
                return value1 != null
            }
        },
        passesConditions(field){
            if(field && field.meta && field.meta.conditions){
                for(let condition of field.meta.conditions){
                    let rules = [];
                    if(condition.rule._and){
                        rules = condition.rule._and;
                    }else{
                        rules = [condition.rule];
                    }
                    for(let rule of rules){
                        let key = Object.keys(rule)[0];
                        let compareOperation = Object.keys(rule[key])[0]
                        let desiredValue = rule[key][compareOperation];
                        let compareValue = this.peopleManager.editData[key];
                        if(this.checkCondition(compareValue, desiredValue, compareOperation)===false){
                            return false;
                        }
                    }
                }
            }
            return true;
        },
        getFieldByName(name){
            for(let field of this.peopleManager.fieldData){
                if (field.field === name){
                    return field;
                }
            }
            return undefined;
        },
        getEditFields(){
            let fieldGroups = [];
            let fields = {}

            let groupTitle = "";
            for(let i=0; i< this.peopleManager.fieldData.length; i++){
                let field = this.peopleManager.fieldData[i];

                let inBackendNotHidden = !field.meta.hidden && this.backendForm;
                let inFrontendAndAlias = !this.backendForm && field.type === "alias";
                let groupHidden = field.meta.group && this.getFieldByName(field.meta.group).meta.hidden;

                if(field.meta.interface !== 'presentation-divider' && i <= this.peopleManager.fieldData.length-1){
                    if(!this.backendForm || inBackendNotHidden || inFrontendAndAlias){
                        if(this.passesConditions(field) && !groupHidden){
                            fields[field.field] = {
                                value: this.peopleManager.editData[field.field],
                                type: field.type,
                                meta: field.meta,
                                groupTitle: groupTitle
                            };
                        }else{
                            const val = field.meta.default_value === undefined ?  null : field.meta.default_value;
                            this.peopleManager.editData[field.field] = val;
                        }
                    }
                }

                if(field.meta.interface === 'presentation-divider' || i === this.peopleManager.fieldData.length-1){
                    if(Object.keys(fields).length > 0){
                        fieldGroups.push({groupTitle:groupTitle, fields:fields});
                        fields = {};
                    }
                    groupTitle = field.meta.note;
                }
            }
            return fieldGroups;
        }
    }
}
</script>

<style scoped>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>