<template>
    <report-card-base
        title="Kosten"
        icon="mdi-currency-eur"
    >
        <template v-slot:content>
            <div>
                <v-simple-table v-if="tableData">
                    <thead>
                        <tr>
                            <th></th>
                            <th v-for="(value, key) in tableData[Object.keys(tableData)[0]]" :key="key">
                                {{ key }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(freizeit, freizeitName) in tableData" :key="freizeitName">
                            <td>{{ freizeitName }}</td>
                            <td v-for="(value, key) in freizeit" :key="key">
                                <strong
                                    v-if="freizeitName ==='Summe'" 
                                    :class="key.includes('Ist')?'success--text':''"
                                >
                                    {{ value }} €
                                </strong>
                                <span v-else
                                    :class="key.includes('Ist')?'success--text':''"
                                > 
                                    {{ value }} €
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </div>

        </template>

    </report-card-base>
</template>

<script>
import ReportCardBase from './ReportCardBase.vue';
export default {
    components:{
        ReportCardBase
    },
    data(){
        return {
            freizeiten:undefined,
            data:undefined,
            tableHeader:[ "Freizeiten", "T-Shirts", "Tassen", "Summe (Soll)", "Summe (Ist)"],
            tableData:undefined,
        }
    },
    props:{
        year:String
    },
    async created(){
        await this.getIncome();
        this.tableData = this.extractTableData(this.data);

    }, 
    methods:{
        async getIncome(){
            let url = "/items/teilnehmer_"+this.year+"?limit=-1";
            url += '&filter={"_and":[{"status_person":{"_eq":"Bestätigt"}}]}'
            url+="&fields=vorname,nachname,freizeit,tshirt,tassen,kosten_bezahlt,kosten_zuschuss,kosten_buchungen";
            let personResult = await this.$network.requestGet(url);
            this.data = personResult.data;

            url = "/items/camp";
            url += `?filter={"_and":[{"jahr":{"_eq":"${this.year.toString()}"}}]}`;
            url += "&fields=freizeit.*,tshirt_preis,tasse_preis"
            let campResult = await this.$network.requestGet(url)
            this.freizeiten = campResult.data.data[0];
        },
        extractTableData(data){
            let table = {};
            for(let person of data.data){
                if(table[person.freizeit] === undefined){
                    table[person.freizeit] = {
                        "Freizeit(Soll)":0,
                        "Freizeit(Ist)":0,
                        "Werbeartikel(Soll)":0,
                        "Werbeartikel(Ist)":0,
                        "Summe(Soll)":0,
                        "Summe(Ist)":0
                    }
                }
                table[person.freizeit]["Freizeit(Soll)"] += this.getFreizeitAmount(person);
                table[person.freizeit]["Freizeit(Ist)"] += this.getPayedFreizeitAmount(person);
                table[person.freizeit]["Werbeartikel(Soll)"] += this.getShirtAmount(person) + this.getCupAmount(person);
                table[person.freizeit]["Werbeartikel(Ist)"] += this.getPayedWerbeArtikelAmount(person);
                table[person.freizeit]["Summe(Soll)"] += this.getFreizeitAmount(person) + this.getShirtAmount(person) + this.getCupAmount(person);
                table[person.freizeit]["Summe(Ist)"] += this.getPayedFreizeitAmount(person) + this.getPayedWerbeArtikelAmount(person);
            }
            
            let sum = {
                "Freizeit(Soll)":0,
                "Freizeit(Ist)":0,
                "Werbeartikel(Soll)":0,
                "Werbeartikel(Ist)":0,
                "Summe(Soll)":0,
                "Summe(Ist)":0
            }
            
            for(let key in table){
                sum["Freizeit(Soll)"] += table[key]["Freizeit(Soll)"];
                sum["Freizeit(Ist)"] += table[key]["Freizeit(Ist)"];
                sum["Werbeartikel(Soll)"] += table[key]["Werbeartikel(Soll)"];
                sum["Werbeartikel(Ist)"] += table[key]["Werbeartikel(Ist)"];
                sum["Summe(Soll)"] += table[key]["Summe(Soll)"];
                sum["Summe(Ist)"] += table[key]["Summe(Ist)"];
            }
            table["Summe"] = sum;
            table = this.sortObject(table);
            return table;
        },
        sortObject(ob){
            let keys = [];
            for(let key in ob){
                keys.push(key);
            }
            keys.sort();
            let sortedOb = {}
            for(let key of keys){
                sortedOb[key] = ob[key];
            }
            return sortedOb;
        },
        getFreizeitByName(name){
            for(let freizeit of this.freizeiten.freizeit){
                if(freizeit.name === name){
                    return freizeit;
                }
            }
            return undefined;
        },
        getShirtAmount(person){
            if(person.tshirt === true){
                return this.freizeiten.tshirt_preis;
            }
            return 0
        },
        getFreizeitAmount(person){
            return this.getFreizeitByName(person.freizeit).preis;
        },
        getCupAmount(person){
            return this.freizeiten.tasse_preis * person.tassen;
        },
        getPayedFreizeitAmount(person){
            const freizeitSoll = this.getFreizeitAmount(person);
            
            let payedAmount = person.kosten_bezahlt + person.kosten_zuschuss;
            if(person.kosten_buchungen !== undefined){
                payedAmount = 0;
                for(let payment of person.kosten_buchungen.payments){
                    if(payment.type.operation === "ADD"){
                        payedAmount += parseFloat(payment.amount);
                    }else if(payment.type.operation === "SUBTRACT"){
                        payedAmount -= parseFloat(payment.amount);
                    }
                }
            }
            return Math.min(freizeitSoll, payedAmount)
        },
        getPayedWerbeArtikelAmount(person){
            const freizeitSoll = this.getFreizeitAmount(person);
            let payedAmount = person.kosten_bezahlt + person.kosten_zuschuss;
            if(person.kosten_buchungen !== undefined){
                payedAmount = 0;
                for(let payment of person.kosten_buchungen.payments){
                    if(payment.type.operation === "ADD"){
                        payedAmount += parseFloat(payment.amount);
                    }else if(payment.type.operation === "SUBTRACT"){
                        payedAmount -= parseFloat(payment.amount);
                    }
                }
            }
            return Math.max(0, payedAmount - freizeitSoll );
        }
    }
}
</script>