<template>
    <v-row>
        <!-- {{localDay}} {{localMonth}} {{localYear}} -->
        <!--<v-text-field
            type="date"
            solo
            hide-details="auto"
            dense
            :value="localValue"
            @input="changeValue"
        /> -->
        <v-col class="pr-0">
            <v-select
                :disabled="disabled"
                label="Tag"
                solo
                dense
                :items="localDays"
                hide-details="auto"
                v-model="day"
                :rules="rules"
            />
        </v-col>
        <v-col class="pr-0">
            <v-select 
                :disabled="disabled"
                label="Monat"
                solo
                dense
                :items="localMonths"
                hide-details="auto"
                v-model="month"
                :rules="rules"
            >
                <template v-slot:item="{ item }">
                    <div v-if="item">
                        {{ monthNames[item-1] }}
                    </div>
                </template>
                <template v-slot:selection="{ item }">
                    <div v-if="item">
                        {{ monthNames[item-1] }}
                    </div>
                </template>
            </v-select>
        </v-col>
        <v-col
            class="mr-0 pr-0"
        >
            <v-select 
                :disabled="disabled"
                label="Jahr"
                solo
                dense
                :items="localYears"
                hide-details="auto"
                v-model="year"
                :rules="rules"
            />
        </v-col>
        <v-col class="ml-0 mr-0 pr-2 pl-0" x-small cols="auto">
            <v-btn icon
                :disabled="disabled"
                @click="$emit('input', null)"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
import dayjs from 'dayjs';

export default {
    mounted(){
        this.localValue = this.value;
        this.day = parseInt(dayjs(this.value, 'YYYY-MM-DD').format("D"));
        this.month = parseInt(dayjs(this.value, 'YYYY-MM-DD').format("MM"));
        this.year = parseInt(dayjs(this.value, 'YYYY-MM-DD').format("YYYY"));
    },
    props:{
        disabled:{
            type:Boolean,
            default:false
        },
        ageFrom:{
            type:Number,
            default:-1
        },
        ageTo:{
            type:Number,
            default:-1
        },
        value:{
            type:String,
            default:""
        },
        rules: {
            type: Array,
            required: false
        },
        ageLimiter:{
            type:Number,
            default:0
        }
    },
    data(){
        return{
            localDays: this.createDaysArray(),
            monthNames: ["Januar","Februar","März","April","Mai","Juni","Juli","August","September","Oktober","November","Dezember"],
            localMonths: [1,2,3,4,5,6,7,8,9,10,11,12],
            localYears: this.createYearsArray(),
            localValue: this.value || "",
            localDay:undefined,
            localMonth:undefined,
            localYear:undefined
        }
    },
    watch:{
        value(value){
            this.localValue = value || "";
        },
    },
    methods:{
        getMonthNumber(name){
            for(let i=0; i<this.months.length; i++){
                if(name === this.months[i]){
                    return (i+1).toString();
                }
            }
        },
        createYearsArray(){
            let yearsArray = [];
            let ageFrom=1940;
            let ageTo=parseInt(dayjs().format("YYYY"))-this.ageLimiter;
            if(this.ageFrom != -1){
                ageFrom = this.ageFrom;
            }
            if(this.ageTo != -1){
                ageTo = this.ageTo;
            }
            for(let i=ageFrom; i<=ageTo; i++){
                yearsArray.push(i);
            }
            yearsArray.reverse();
            return yearsArray;
        },
        createDaysArray(){
            var daysArray = [];
            for(let i=1; i<=31; i++){
                daysArray.push(i)
            }
            return daysArray;
        },
        changeValue(event){
            this.$emit("input", event);
        }
    },
    computed:{
        day:{
            get(){
                return parseInt(dayjs(this.value, 'YYYY-MM-DD').format("DD"));
            },
            set(val){
                this.localDay = val;
                if(this.localYear  && this.localMonth && this.localDay){
                    let dateString = this.localYear.toString() + "-" + this.localMonth.toString().padStart(2,'0') + "-" + this.localDay.toString().padStart(2,'0');
                    this.$emit("input", dateString);
                }
            }
        },
        month:{
            get(){
                return parseInt(dayjs(this.value, 'YYYY-MM-DD').format("MM"))
            },
            set(val){
                this.localMonth = val;
                if(this.localYear  && this.localMonth && this.localDay){
                    let dateString = this.localYear.toString() + "-" + this.localMonth.toString().padStart(2,'0') + "-" + this.localDay.toString().padStart(2,'0');
                    this.$emit("input", dateString);
                }
            }
        },
        year:{
            get(){
                return parseInt(dayjs(this.value, 'YYYY-MM-DD').format("YYYY"));
            },
            set(val){
                this.localYear = val;
                if(this.localYear  && this.localMonth && this.localDay){
                    let dateString = this.localYear.toString() + "-" + this.localMonth.toString().padStart(2,'0') + "-" + this.localDay.toString().padStart(2,'0');
                    this.$emit("input", dateString);
                }
            }
        }
    }
}
</script>