import dayjs from 'dayjs';

export class DirectusNetworking{
    app = undefined;
    sortedFields = [];
    loggedIn = false;
    refreshTimeout = undefined;
    sessionExpireTimestamp = undefined;
    deviceOnline = true;

    async setup(app){
        this.app = app; 
        document.addEventListener("visibilitychange", this.onVisibilityChanged.bind(this));
        window.addEventListener("online", this.onDeviceOnline.bind(this));
        window.addEventListener("offline", this.onDeviceOffline.bind(this));
    }

    onDeviceOffline(){
        this.deviceOnline = false;
        this.app.showNotification("Du bist offline.", -1, "error", "mdi-wifi-off");
    }

    onDeviceOnline(){
        this.deviceOnline = true;
        this.app.showNotification("Du bist wieder online.", 2000, "success", "mdi-wifi");
    }

    onVisibilityChanged(){
        if(document.visibilityState === "visible"){
            const timestamp = Date.now();
            if(timestamp > this.sessionExpireTimestamp-10000){
                this.refreshUserLogin();
            }
        }
    }

    async loginUser(email, password, redirect=true){
        let payload = {email:email, password:password};
        let result = await this.requestPost("/auth/login", payload)
        if (result.status === 200){
            let data = result.data.data;

            this.app.loggedIn = true;
            // this.app.loggedIn = true;
            this.app.$axios.defaults.headers.common['Authorization'] = 'Bearer '+ data.access_token;
            this.app.$cookie.set("access_token", data.access_token);
            this.app.$cookie.set("refresh_token", data.refresh_token);
            if(redirect){
                this.app.$router.replace({'name':'Dashboard'});
                this.app.loadLastRoute();
            }

            // refresh session after expired time minus 10 seconds
            this.sessionExpireTimestamp = Date.now() + data.expires;

            this.refreshTimeout = setTimeout(()=>{
                this.refreshUserLogin();
                this.refreshTimeout = undefined;
            }, data.expires - 10000);
        }
        return result;
    }

    isSuperAdmin(){
        if(this.app.userData === undefined) return;
        if(this.app.userData.role.name === "SuperAdmin") return true;
        return false;
    }

    async logoutUser(){
        let refreshToken = this.app.$cookie.get("refresh_token");
        let result = await this.requestPost("/auth/logout", {refresh_token:refreshToken});
        if(result.status === 200 || result.status === 204){
            this.app.loggedIn = false;
            this.app.userData = undefined;
            this.app.$cookie.delete("access_token");
            this.app.$cookie.delete("refresh_token");
            
            if(this.app.$router.currentRoute.name != "Login"){
                this.app.$router.replace({"name":"Login"});
            }
        }
    }

    async refreshUserLogin(){
        console.log("Refreshing Session " + dayjs().format("YYYY-MM-DD HH:mm:ss"))
        let refresh_token = this.app.$cookie.get("refresh_token");
        let payload = {
            refresh_token: refresh_token
        }
        
        delete this.app.$axios.defaults.headers.common['Authorization'];
        if (refresh_token){
            let result = await this.requestPost("/auth/refresh", payload);
            if(result.status === 200){
                let data = result.data.data;
                this.app.$axios.defaults.headers.common['Authorization'] = 'Bearer '+ data.access_token;
                this.app.$cookie.set("access_token", data.access_token);
                this.app.$cookie.set("refresh_token", data.refresh_token);

                await this.checkIfLoggedIn();
    
                // refresh session after expired time minus 10 seconds
                this.sessionExpireTimestamp = Date.now() + data.expires;
                this.refreshTimeout = setTimeout(()=>{
                    this.refreshUserLogin();
                    this.refreshTimeout = undefined;
                }, data.expires - 10000);
                this.app.loggedIn = true;
                
            }else{
                this.app.loggedIn = false;
            }
            return result;
        }
        return {result:401};
    }

    // actions: read, create, update, delete, share
    async hasPermission(roleID, collection, action){
        let urlRole = "/roles/"+roleID;
        let roleResult = await this.requestGet(urlRole);
        if (roleResult.status === 200){
            if(roleResult.data && roleResult.data.data && roleResult.data.data.admin_access){
                return roleResult.data.data.admin_access;
            }
        }

        let url = "/permissions";
        url += "?filter[collection][_eq]="+collection;
        url += "&filter[action][_eq]="+action;
        url += "&filter[role][_eq]="+roleID;
        let result = await this.requestGet(url);
        if (result.status === 200){
            if (result.data && result.data.data && result.data.data.length > 0){
                return true;
            }
        }
        return false;
    }

    async checkIfMaintainanceModeActive(){
        let result = await this.requestGet("/items/website");
        if(result.status === 200){
            return result.data.data.maintainance_mode
        }
        return undefined;
    }

    async checkIfLoggedIn(redirect=false){
        // let accessToken = this.app.$cookie.get("access_token");
        // this.app.$axios.defaults.headers.common['Authorization'] = 'Bearer '+ accessToken;
        let result = await this.requestGet("/users/me");
        if(result.status === 200){
            if(!this.app.userData){
                let userData = result.data.data;
                let userRole = await this.getRole(userData.role);
                this.app.userData = userData;
                if(userRole !== undefined){
                    this.app.userData.role = userRole;
                }
            }
            if(redirect){
                this.app.$router.replace({'name':'Dashboard'});
            }
            this.app.loggedIn = true;
        }else{  
            delete this.app.$axios.defaults.headers.common['Authorization'];
            this.rerouteIfRequired()
            this.app.loggedIn = false;
        }
    }

    rerouteIfRequired(){
        if(this.app.$router.currentRoute.meta.requires_auth){
            this.app.$router.replace({name:'Login'})
        }
    }

    async loadTablePreset(userRole, collection){
        // let result = await this.requestGet('/presets?filter={"role":{"_eq":"'+userRole+'"}}');
        let result = await this.requestGet('/presets?filter={"_and":[{"role":{"_eq":"'+userRole+'"}},{"collection":{"_eq":"'+collection+'"}}]}');
        if(result.status === 200){
            for(let preset of result.data.data){
                if(preset.collection === collection && preset.layout_query.tabular.fields){
                    return preset;
                }
            }
            // return result.data.data[0];
        }
        if (result.data.length === undefined){
            // let result = await this.requestGet('/presets?filter={"_and":[{"role":{"_null":null}},{"user":{"_null":null}},{"collection":{"_eq":"'+collection+'"}}]}');
            let result = await this.requestGet('/presets?filter={"_and":[{"role":{"_null":null}},{"collection":{"_eq":"'+collection+'"}}]}');
            if(result.status === 200){
                for(let preset of result.data.data){
                    if(preset.collection === collection && preset.layout_query.tabular.fields){
                        return preset;
                    }
                }
            }
        }
        console.log("No Table Preset found. Create a Preset for logged in User Role.")
        return undefined;
    }

    buildTree(groups, fields){
        for(let field of fields){
            this.sortedFields.push(field);
            if(groups[field.field] !== undefined){
                this.buildTree(groups, groups[field.field]);
            }
        }
    }

    clearAuthHeader(){
        let authHeader;
        if(this.app.$axios.defaults.headers.common['Authorization']){
            authHeader = JSON.parse(JSON.stringify(this.app.$axios.defaults.headers.common['Authorization']));
            delete this.app.$axios.defaults.headers.common['Authorization'];
        }
        return authHeader;
    }
    
    async getFieldData(collection, field, withoutAuth=false){
        let result = await this.requestGet("/fields/"+collection+"/"+field, withoutAuth);
        if(result.status === 200){
            return result.data.data;
        }
        return undefined;
    }

    async getFieldDataRaw(collection){
        let result = await this.requestGet('/fields/'+collection);
        if(result.status === 200){
            return result.data.data;
        }
        return [];
    }

    async getFieldsData(collection, withoutAuth=false){
        let result = await this.requestGet('/fields/'+collection, withoutAuth);
        if(result.status === 200){
            let fields = result.data.data;
            let groups = {};
            // gather single groups
            for(let field of fields){
                if(groups[field.meta.group] === undefined){
                    groups[field.meta.group] = [];
                }
                groups[field.meta.group].push(field);
            }

            // sort groups
            for(let key in groups){
                groups[key].sort((a,b)=>{
                    if(!a.meta.sort){
                        a.meta.sort = Infinity;
                    }
                    if(!b.meta.sort){
                        b.meta.sort = Infinity;
                    }
                    return a.meta.sort - b.meta.sort;
                });
            }

            // build group tree structure
            this.sortedFields = [];
            this.buildTree(groups, groups[null]);

            return this.sortedFields;
        }
        return undefined;
    }

    async getCampListByYear(){
        let result = await this.requestGet("/items/camp?fields=jahr");
        if(result.status === 200){
            let camps = [];
            for(let i=0; i<result.data.data.length; i++){
                let camp = result.data.data[i];
                camps.push(camp.jahr.toString());
            }
            return camps;
        }
        return [];
    }

    async getCampData(year){
        let endpoint = "/items/camp?filter[jahr][_eq]="+year+"&fields=*,freizeit.*";
        let result = await this.requestGet(endpoint);
        if(result.status === 200){
            return result.data.data[0];
        }
        return undefined;
    }

    async getRole(roleId){
        let result = await this.requestGet("/roles/"+roleId+"?fields=*,main_menu_access.main_menu_id,camp_menu_access.camp_menu_id,inventory_menu_access.inventory_menu_id");
        if(result.status === 200){
            return result.data.data;
        }
        return undefined
    }

    async requestFreizeit(year, campName){
        let url = "/items/camp?fields=freizeit.*"
        url += "&filter[jahr][_eq]="+year;
        let result = await this.requestGet(url);
        if(result.status === 200){
            for(let freizeit of result.data.data[0].freizeit){
                if(freizeit.name == campName){
                    return freizeit
                }
            }
        }
        return undefined;
    }

    async requestPeopleData(collection, filters){
        let url = '/items/'+collection;
        url += '?sort=-id'
        url += '&limit=-1'
        if (filters && filters.length > 0){
            url += '&filter={'
            url += '"_and":['
            let i = 0;
            for (let filter of filters){
                i++;
                if (filter.selected !== undefined){
                    if(filter.selected === "*"){
                        url += `{"${filter.key}":{"_in":${JSON.stringify(filter.options)}}}`;
                    }else if(filter.type === 'boolean' || filter.type === 'integer'){
                        if(typeof(filter.selected) === "string" && filter.options){
                            url += `{"${filter.key}":{"${filter.operator}":${JSON.stringify(filter.selected==="Ja"?1:0)}}}`;
                        }else{
                            url += `{"${filter.key}":{"${filter.operator}":${JSON.stringify(filter.selected)}}}`;
                        }
                    }else if(filter.type === 'string'){
                        if(filter.operator === '_eq'){
                            url += `{"${filter.key}":{"_in":${JSON.stringify(filter.selected)}}}`;
                        }else{
                            url += `{"${filter.key}":{"_nin":${JSON.stringify(filter.selected)}}}`;
                        }
                    }else if(filter.type === 'json'){
                        if(filter.operator === '_eq'){
                            url += `{"${filter.key}":{"_contains":${JSON.stringify(filter.selected)}}}`;
                        }else{
                            url += `{"${filter.key}":{"_ncontains":${JSON.stringify(filter.selected)}}}`;
                        }
                    }else if(filter.type === 'date' && filter.selected !== ""){
                        url += `{"${filter.key}":{"${filter.operator}":${JSON.stringify(filter.selected)}}}`;
                    }else if((filter.type === 'float' || filter.type === 'integer') && filter.selected !== ""){
                        url += `{"${filter.key}":{"${filter.operator}":${JSON.stringify(filter.selected)}}}`;
                    }
                    // if (filter.type === 'boolean'){
                    //     url += `{"${filter.key}":{"_eq":${JSON.stringify(filter.selected)}}}`;
                    // }else if(filter.type === 'string'){
                    //     url += `{"${filter.key}":{"_in":${JSON.stringify(filter.selected)}}}`;
                    // }else if(filter.type === 'json'){
                    //     url += `{"${filter.key}":{"_contains":${JSON.stringify(filter.selected)}}}`;
                    // }
                    if(i < filters.length){
                        url += ",";
                    }
                }
            }
            url += ']}'
        }
        let result = await this.requestGet(url);
        return result;
    }

    async requestAssetAsBase64(endPoint, withoutAuth){
        let authHeader;
        if(withoutAuth){
            authHeader = this.clearAuthHeader()
        }
        // let url = this.app.restUrl + endPoint;
        let url = process.env.VUE_APP_REST_URL + endPoint;
        if(endPoint.indexOf("http:") !== -1){
            url = endPoint;
        }
        return new Promise(resolve =>{
            this.app.$axios.get(url, {responseType:'arraybuffer'})
            .then(result =>{
                if(authHeader){
                    this.app.$axios.defaults.headers.common['Authorization'] = authHeader;
                }
                result.data = Buffer.from(result.data, 'binary').toString('base64');
                resolve(result);
                // resolve(result);
            })
            .catch(error=>{
                if(authHeader){
                    this.app.$axios.defaults.headers.common['Authorization'] = authHeader;
                }
                resolve({status:400, message:error});
            })
        });
    }

    async requestGet(endPoint, withoutAuth){
        let authHeader;
        if(withoutAuth){
            authHeader = this.clearAuthHeader()
        }
        // let url = this.app.restUrl + endPoint;
        let url = process.env.VUE_APP_REST_URL + endPoint;
        return new Promise(resolve =>{
            this.app.$axios.get(url)
            .then(result =>{
                if(authHeader){
                    this.app.$axios.defaults.headers.common['Authorization'] = authHeader;
                }
                resolve(result);
            })
            .catch(error=>{
                if(authHeader){
                    this.app.$axios.defaults.headers.common['Authorization'] = authHeader;
                }
                resolve({status:400, message:error});
            })
        });
    }

    async requestPost(endPoint, payload, withoutAuth){
        let authHeader;
        if(withoutAuth){
            authHeader = this.clearAuthHeader()
        }

        // let url = this.app.restUrl+endPoint;
        let url = process.env.VUE_APP_REST_URL + endPoint;
        return new Promise(resolve =>{
            this.app.$axios.post(url, payload)
            .then(result=>{
                if(authHeader){
                    this.app.$axios.defaults.headers.common['Authorization'] = authHeader;
                }
                resolve(result);
            })
            .catch(error=>{
                if(authHeader){
                    this.app.$axios.defaults.headers.common['Authorization'] = authHeader;
                }
                if(error.response && error.response.data){
                    resolve({status:400, message:error.response.data});
                }else{
                    resolve({status:400, message:error});
                }
            });
        })
    }

    async requestDelete(endPoint, items){
        // let url = this.app.restUrl+endPoint;
        let url = process.env.VUE_APP_REST_URL + endPoint;
        return new Promise(resolve => {
            this.app.$axios.delete(url, items)
            .then(result=>{
                resolve(result);
            })
            .catch(error=>{
                resolve({status:400, message:error});
            })
        });
    }

    async requestPatch(endPoint, payload, withoutAuth){
        let authHeader;
        if(withoutAuth){
            authHeader = this.clearAuthHeader()
        }
        // let url = this.app.restUrl+endPoint;
        let url = process.env.VUE_APP_REST_URL + endPoint;
        return new Promise(resolve => {
            this.app.$axios.patch(url, payload)
            .then(result => {
                if(authHeader){
                    this.app.$axios.defaults.headers.common['Authorization'] = authHeader;
                }
                resolve(result);
            })
            .catch(error => {
                if(authHeader){
                    this.app.$axios.defaults.headers.common['Authorization'] = authHeader;
                }
                resolve({status:400, message:error});
            })
        });
    }

    async peopleManagerPostSaveOperations(collection, camp, person){
        // only make post save operations if both fields are found. Otherwise abort.
        if(!person.status_fuehrungszeugnis || !person.status_fuehrungszeugnis_datum){
            return;
        }
        // check führungszeugnis date and set state
        let payload = {status_fuehrungszeugnis:"Unbekannt"};
        if(!person.status_fuehrungszeugnis_datum){
            payload.status_fuehrungszeugnis = "Unbekannt";
        }else{
            let dateExpired = false;
            for(let freizeit of camp.freizeit){
                let dateZeugnis = dayjs(person.status_fuehrungszeugnis_datum).add(5,'year');
                if(dayjs(freizeit.datum_bis).isAfter(dateZeugnis)){
                    payload.status_fuehrungszeugnis = "Abgelaufen";
                    dateExpired = true
                    break;
                }
            }
            if(!dateExpired){
                payload.status_fuehrungszeugnis = "Aktuell";
            }
            // payload.status_fuehrungszeugnis = "Unbekannt";
            // payload.status_fuehrungszeugnis_datum = null;
        }
        // console.log(person.id,"-",payload)
        this.requestPatch("/items/"+collection+"/"+person.id, payload);
    }

}