<template>
    <div>
        <v-container>
            <v-alert 
                text
                border="left"
                type="warning"
                icon="mdi-account-hard-hat"
            >  
            <v-card-title>
                    Die Webseite befindet sich im Wartungsmodus.
                </v-card-title>
                <v-card-subtitle v-if="websiteData">
                    {{ websiteData.maintainance_message }}
                </v-card-subtitle>
                <v-card-text>
                    <v-row v-if="$network.isSuperAdmin()">
                        <v-col>
                            <v-btn @click="disableMaintainanceMode()" color="warning">Wartungsmodus deaktivieren</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-alert>
            <login v-if="app.userData === undefined"/>
        </v-container>
    </div>
</template>

<script>
import Login from '../pages/Login.vue';

export default {
    name:"Maintainance",
    components:{
        Login
    },
    data(){
        return{
            websiteData:undefined
        }
    },
    inject:[
      "app",
    ],
    async created(){
        let result = await this.$network.requestGet("/items/website");
        if(result.status === 200){
            this.websiteData = result.data.data;
        }
    },
    methods:{
        async disableMaintainanceMode(){
            let url = "/items/website";
            let payload = {maintainance_mode:false}
            let result = await this.$network.requestPatch(url, payload)
            if(result.status === 200){
                this.app.maintainanceMode = false;
            }
        }
    }
}
</script>

<style scoped>
    #body{
        width:100%;
        height:100%;
        background-color:rgb(245, 245, 245);
    }
</style>