<template>
    <v-row>
    
        <!-- Header Buttons -->
        <portal to="header-tools">
            <div 
                class="pr-3 d-flex"
            >
                <div v-if="!editData">
                    <v-btn 
                        :disabled="!dashboard.campDataEditable"
                        v-if="peopleSelectedRows.length > 0" 
                        class="error mr-2" fab x-small elevation="2" @click="$refs.delDialog.show()"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>

                    <v-btn 
                        :disabled="!dashboard.campDataEditable"
                        v-if="peopleSelectedRows.length > 0" 
                        class="primary mr-2" fab x-small elevation="2" @click="openEmailClient()"
                    >
                        <v-icon>mdi-email</v-icon>
                    </v-btn>
                    
                    <v-btn :disabled="editData !== undefined || !dashboard.campDataEditable" class="primary" fab x-small elevation="2" @click="createEmptyDataSet()"> <v-icon>mdi-account-plus</v-icon> </v-btn>
                </div>
                
                <div v-else>
                    <v-btn :disabled="editData === undefined" class="error mr-2" fab x-small elevation="2" @click="cancelEdit()"> <v-icon>mdi-close-thick</v-icon> </v-btn>
                    <v-btn v-if="editedId !== -1" :disabled="editData === undefined || !dashboard.campDataEditable" class="primary mr-2" fab x-small elevation="2" @click="saveEdit()"> <v-icon>mdi-check-bold</v-icon> </v-btn>
                    <v-btn v-else :disabled="editData === undefined || !dashboard.campDataEditable" class="primary mr-2" fab x-small elevation="2" @click="saveNewDataSet()"> <v-icon>mdi-check-bold</v-icon> </v-btn>
                </div>
                <transfer-field 
                    :disabled="!dashboard.campDataEditable"
                    v-if="!editData && collection === 'mitarbeiter_' && app.userData.role.admin_access"
                    class="ml-2"
                    :year="year"
                    :collection="collection"
                    :peopleManager="peopleManager"
                />
                <export-dialog
                     v-if="editData === undefined"
                    :collection="collectionWithYear"
                    :fileName="collectionWithYear"
                    :peopleManager="peopleManager"
                />
            </div>
        </portal>

        <v-col>
            <v-card id="TableContent" :loading="loadingData">
                <v-card-title>
                    Stammdaten 
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="hasCreatePermission && $network.isSuperAdmin()"
                        class="" icon
                        @click="openCollectionEditor"
                    >
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </v-card-title>
                <div :class="{'pa-0':isMobile()} && !loadingData">
                    <!-- {{this.peopleData}} -->
                    <v-expand-transition>
                        <v-card
                            class="ml-0 mr-0"
                            v-show="!loadPeopleForm"
                            v-if="editData && (editedId === -1 || peopleManager.expandedRow !== -1)"
                        >
                            <v-card-title>{{formEditorTitle}}</v-card-title>
                            <v-card-text
                                
                                class="grey lighten-4 ml-0 mr-0 pa-0"  
                            >
                                <people-form-editor
                                    :people-manager="peopleManager"
                                    :onCancel="cancelEdit"
                                    :onSave="saveNewDataSet"
                                    :dataValidation="false"
                                    :year="parseInt(dashboard.activeCampYear)"
                                    :editable="dashboard.campDataEditable"
                                />
                            </v-card-text>
                        </v-card>
                    </v-expand-transition>
                    <people-table
                        ref="PeopleTable"
                        :access-forbidden="accessForbidden"
                        :dashboard="dashboard"
                        :people-manager="peopleManager"
                        :collection="collectionWithYear"
                    />

                    <v-container
                        v-if="loadPeopleForm"
                    >
                        <v-row
                            class="fill-height"
                            align-content="center"
                            justify="center"
                        >
                            <v-col
                            class="text-subtitle-1 text-center"
                            cols="12"
                            >
                            Lade Datensatz
                            </v-col>
                            <v-col cols="6">
                            <v-progress-linear
                                color="primary"
                                indeterminate
                                rounded
                                height="6"
                            ></v-progress-linear>
                            </v-col>
                        </v-row>
                    </v-container>

                    
                    <v-alert
                        v-if="accessForbidden"
                        text
                        prominent
                        type="error"
                        icon="mdi-account-alert"
                    >
                        Sie haben keine Berechtigung die Daten zu sehen.
                    </v-alert>
                </div>
            </v-card>
        </v-col>
        <confirm-dialog 
            ref="delDialog"
            icon="mdi-alert"
            icon-color="error--text"
            title="Personendaten wirklich löschen?"
            text="Ausgewählte Personendaten werden unwiderruflich gelöscht."
            :max-width="400"
            :onConfirm="deleteSelectedItems"
        />
        <v-snackbar :color="snackbar.color" v-model="snackbar.show"><v-icon>{{snackbar.icon}}</v-icon>{{snackbar.text}}</v-snackbar>
        <employee-confirmation
            :peopleManager="peopleManager"
         ref="EmployeeConfirmation" />        
        <participant-confirmation
            :peopleManager="peopleManager"
         ref="ParticipantConfirmation" />
         <email-client
            v-if="dashboard"
            ref="EmailClient"
            :year="dashboard.activeCampYear"
         />
         <collection-editor
            ref="CollectionEditor"
            :year="simpleCollection ? '' : dashboard.activeCampYear"
            :collection="collection"
         />
    </v-row>
</template>

<script>
import PeopleTable from '@/components/PeopleTable.vue';
import PeopleFormEditor from './PeopleFormEditor.vue';
import EmailClient from './email/EmailClient.vue'
import ConfirmDialog from './ConfirmDialog.vue';
import EmployeeConfirmation from '@/components/email/EmployeeConfirmation.vue'
import ParticipantConfirmation from '@/components/email/ParticipantConfirmation.vue'
import ExportDialog from './ExportDialog.vue';
import dayjs from 'dayjs';
import get from 'lodash.get';
import TransferField from './TransferFieldValue.vue';
import CollectionEditor from './CollectionEditor/CollectionEditor.vue';

export default {
    name:"PeopleManager",
    inject:[
        "app"
    ],
    created(){
    },
    async mounted(){
        if(this.dashboard.app.dataRefreshTimer){
            clearInterval(this.dashboard.app.dataRefreshTimer);
        } 
        await this.requestWritePermission();
        this.dashboard.app.dataRefreshTimer = setInterval(()=>{
            this.loadPeopleData(false);
        },10000);
        await this.$network.checkIfLoggedIn();
        this.reloadData();
    },
    beforeDestroy(){
        if(this.dashboard.app.dataRefreshTimer){
            clearInterval(this.dashboard.app.dataRefreshTimer);
        } 
    },
    components:{
        EmailClient,
        PeopleTable,
        PeopleFormEditor,
        ConfirmDialog,
        EmployeeConfirmation,
        ParticipantConfirmation,
        ExportDialog,
        TransferField,
        CollectionEditor
    },
    props:{
        dashboard:Object,
        title:{type:String, default:"Mitarbeiter"},
        collection:{type:String, default:"mitarbeiter"},
        year:{type:String, default:"2022"},
        simpleCollection:{type:Boolean, default:false},
    },
    data(){
        return{
            reloadTimer:undefined,
            hasCreatePermission:false,
            writePermissionFields:[],
            accessForbidden:false,
            peopleManager:this,
            editData:undefined,
            editedId:undefined,
            tablePreset:undefined,
            fieldData:undefined,
            peopleData:undefined,
            peopleSearch:'',
            peopleSelectedRows:[],
            formEditorTitle: "Neuer Datensatz",
            peopleFilter:[],
            filterPresets:undefined,
            loadingData:false,
            loadPeopleForm:false,
            propertyTypes:{},
            propertyIcons:{},
            waitingForServerResponse:false,
            expandedRow:-1,
            dataValidation:undefined,

            snackbar:{
                color:"success",
                icon:"mdi-check-bold",
                text:"",
                show:false
            }
        }
    },
    computed:{
        collectionWithYear(){
            if(this.simpleCollection){
                return this.collection;
            }
            return this.collection + this.year;
        },
        cleanedEditData(){
            let cleanedEditData = {}
            for(let key in this.editData){
                if(this.writePermissionFields.indexOf("*") !== -1 || this.writePermissionFields.indexOf(key) !== -1){
                    cleanedEditData[key] = this.editData[key];
                }
            }
            return cleanedEditData;
        }
    },
    methods:{
        async requestWritePermission(){
            if(this.app.userData.role.admin_access){
                this.writePermissionFields = ["*"];
                return;
            }
            const roleId = this.app.userData.role.id;
            let url = `/permissions?filter={"_and":[{"role":{"_eq":"${roleId}"}},{"collection":{"_eq":"${this.collectionWithYear}"}},{"action":{"_eq":"update"}}]}`;

            let result = await this.$network.requestGet(url);
            if(result.status === 200){
                if(result.data.data.length > 0){
                    const permission = result.data.data[0];
                    this.writePermissionFields = permission.fields;
                }
            }
        },
        openCollectionEditor(){
            this.$refs.CollectionEditor.show();
        },
        openEmailClient(){
            this.$refs.EmailClient.show(this.peopleSelectedRows, this.collectionWithYear, this.dashboard.activeCampYear);
        },
        isMobile(){
            return this.$vuetify.breakpoint.xsOnly;
        },
        getFilterPresets(){
            if(!this.fieldData) return [];
            let filters = [];
            // frontend filter
            var filteredArray = this.fieldData.filter(function(field){
                return field.field === "mitarbeiter_position"
            })
            if(filteredArray.length > 0){
                for(let freizeit of this.dashboard.activeCampData.freizeit){
                    let options = [];
                    options.push("*")
                    for(let position of freizeit.mitarbeiter_positionen){
                        options.push(position.position);
                    }
                    let text = "mitarbeiter_position_"+freizeit.name.toLowerCase();//+".position";
                    let key = "mitarbeiter_position"
                    let path = freizeit.name+".position";
                    let filter = {key:key, text:text, path:path, options:options, selected:undefined, type:"json", operator:"_eq", source:"frontend"}
                    filters.push(filter)
                }
            }
            filteredArray = this.fieldData.filter(function(field){
                return field.field === "kosten_buchungen"
            })
            if(filteredArray.length > 0){
                let field = filteredArray[0];
                if(field.meta && field.meta.options && field.meta.options.type){
                    for(let option of field.meta.options.type){
                        let text = "buchungen_"+option.name.toLowerCase();
                        let filter = {key:"kosten_buchungen", text:text, path:option.name, options:"", selected:undefined, type:"integer", operator:"_eq", source:"frontend"}
                        filters.push(filter)
                    }
                }
            }

            // backend filter
            for(let field of this.fieldData){
                if(field.type === "integer" || field.type === "float"){
                    let filter = {key:field.field, text:field.field, selected:undefined, type:field.type, operator:"_eq", source:"backend"}
                    filters.push(filter);
                }else if(field.meta && field.field && field.meta.options && field.meta.options.choices){
                    if(field.meta.options.choices){
                        let filter = {key:field.field, text:field.field, options:[], selected:undefined, type:field.type, operator:"_eq", source:"backend"}
                        filter.options.push("*");
                        for(let option of field.meta.options.choices){
                            filter.options.push(option.value)
                        }
                        if(field.type === "json"){
                            filter.source = "frontend";
                        }
                        filters.push(filter)
                    }
                }else if(field.type === 'dateTime' || field.type === 'date' || field.type === 'string'){
                    if(!field.meta.display_options){
                        let filter = {key:field.field, text:field.field, selected:undefined, type:field.type, operator:"_eq", source:"backend"}
                        filters.push(filter);
                    }
                }
                if(field.meta && field.meta.display_options && field.meta.display_options.status){
                    if(field.meta.display_options.status){
                        let filter = {key:field.field, text:field.field, options:[], selected:undefined, type:field.type, operator:"_eq", source:"backend"}
                        filter.options.push("*");
                        for(let status of field.meta.display_options.status){
                            filter.options.push(status.name);
                        }
                        filters.push(filter)
                    }
                }
                if(field.type === "boolean" || field.meta.interface === "boolean"){
                    let filter = {key:field.field, text:field.field, options:["Ja", "Nein"], selected:undefined, type:field.type, operator:"_eq", source:"backend"}
                    filters.push(filter)
                }
            }
            return filters;
        },
        getSelectedFilter(source="backend"){
            let filters = [];
            for(let filter of this.peopleFilter){
                if (filter.selected !== undefined && filter.source === source){
                    let newFilter = JSON.parse(JSON.stringify(filter))
                    if (filter.type === 'boolean'){
                        newFilter.options = [true, false]
                        if(filter.selected === 'Ja'){
                            newFilter.selected = true
                        }else if (filter.selected === 'Nein'){
                            newFilter.selected = false
                        }
                    }
                    filters.push(newFilter)
                }
            }
            return filters;
        },
        filterDataWithFrontentFilter(data){
            let filteredData = [];
            let frontendFilter = this.getSelectedFilter("frontend");
            if(frontendFilter.length === 0){
                return data;
            }
            for(let dataSet of data){
                let passedAllFilter = false;
                for(let key in dataSet){
                    for(let filter of frontendFilter){
                        if(key === filter.key){
                            if(key === "kosten_buchungen"){
                                let field = dataSet[key];
                                let value = 0
                                for(let payment of field.payments){
                                    if(payment.type.name === filter.path){
                                        value += parseFloat(payment.amount);
                                    }
                                }
                                
                                if(filter.operator === "_gt" && value > parseFloat(filter.selected)){
                                    passedAllFilter = true
                                }else if(filter.operator === "_gte" && value >= parseFloat(filter.selected)){
                                    passedAllFilter = true
                                }else if(filter.operator === "_lt" && value < parseFloat(filter.selected)){
                                    passedAllFilter = true
                                }else if(filter.operator === "_lte" && value <= parseFloat(filter.selected)){
                                    passedAllFilter = true
                                }else if(filter.operator === "_eq" && value == parseFloat(filter.selected)){
                                    passedAllFilter = true
                                }else if(filter.operator === "_neq" && value != parseFloat(filter.selected)){
                                    passedAllFilter = true
                                }else{
                                    passedAllFilter = false
                                    break;
                                }
                            }else{
                                let field = dataSet[key]
                                let value = filter.path ? get(field,filter.path) : field;
                                const inArray = value && value.indexOf(filter.selected) !== -1;
                                if (filter.operator === "_eq"){
                                    if(value === filter.selected || (filter.selected === "*" && value) || inArray){
                                        passedAllFilter = true;
                                    }
                                }else if(filter.operator === "_neq" && !inArray){
                                    if(value !== filter.selected){
                                        passedAllFilter = true;
                                    }
                                }else{
                                    passedAllFilter = false;
                                    break;
                                }
                            }
                        }
                    }
                }
                if(passedAllFilter){
                    filteredData.push(dataSet)
                }
            }
            return filteredData;
        },
        openEmployeeConfirmation(id){
            this.$refs.EmployeeConfirmation.show();
            this.$refs.EmployeeConfirmation.loadEmailVariables(id);
        },
        openParticipantConfirmation(id){
            this.$refs.ParticipantConfirmation.show();
            this.$refs.ParticipantConfirmation.loadEmailVariables(id);
        },

        async editDataSet(id){
            for(let index in this.peopleData){
                let dataSet = this.peopleData[index];
                if(dataSet.id == id){
                    let editData = {};
                    for(let key in dataSet){
                        editData[key] = dataSet[key]
                    }

                    this.editData = editData;
                    this.editedId = id;
                    break
                }
            }
        },

        async loadDataSet(id){
            let result = await this.$network.requestGet("/items/"+this.collectionWithYear+"/"+id);
            if(result.status === 200){
                return result.data;
            }
            return undefined
        },  

        async loadFieldData(collection){
            let result = await this.$network.getFieldsData(collection);
            return result;
        },

        async loadTablePreset(roleId, collection){
            return await this.$network.loadTablePreset(roleId, collection);
        },

        reloadData(){
            this.loadPeopleData(true, true);
        },
        async loadPeopleData(showLoading=true, forceReload=false){
            let roleID = this.dashboard.app.userData.role.id;
            if(this.dashboard.activeCampYear || forceReload){
                if(showLoading){
                    this.loadingData = true;
                }
                this.hasCreatePermission = await this.$network.hasPermission(roleID, this.collectionWithYear, "create");
                this.fieldData = await this.loadFieldData(this.collectionWithYear);
                this.filterPresets = this.getFilterPresets();
                this.tablePreset = await this.loadTablePreset(this.dashboard.app.userData.role.id, this.collectionWithYear);
                
                let result = await this.$network.requestPeopleData(this.collectionWithYear, this.getSelectedFilter("backend"));
                if (result.status === 200){
                    this.accessForbidden = false;
                    this.peopleData = this.filterDataWithFrontentFilter(result.data.data);
                    
                    // this.$refs["PeopleTable"].fields = this.peopleData;
                }else if(result.status === 400){
                    this.accessForbidden = true;
                }
                this.loadingData = false;
            }

        },

        async deleteSelectedItems(){
            let ids = [];
            for(let row of this.peopleSelectedRows){
                ids.push(parseInt(row.id))
            }
            await this.$network.requestDelete("/items/"+this.collectionWithYear, {data:ids});
            this.loadPeopleData();

            this.snackbar.show = true;
            this.snackbar.color = "error";
            this.snackbar.icon = "mdi-delete";
            this.snackbar.text = "Ausgewählte Einträge wurden gelöscht."
            this.peopleSelectedRows = [];
        },

        async deleteDataSet(id){
            await this.$network.requestDelete("/items/"+this.collectionWithYear+"/"+id);
            this.loadPeopleData();
        },

        async createEmptyDataSet(){
            this.formEditorTitle = "Neuer Datensatz";
            this.editData = await this.getEmptyDataSet();
        },
        async getEmptyDataSet(){
            let fields = await this.$network.requestGet("/fields/"+this.collectionWithYear);
            this.editedId = -1;
            let editData = {};
            for(let field of fields.data.data){
                if(!field.meta.hidden){
                    if(field.schema){
                        editData[field.field] = field.schema.default_value;
                    }else{
                        editData[field.field] = undefined;
                    }
                }
            }
            return editData;
        },

        async saveNewDataSet(){
            if(this.editedId === -1){
                let payload = {};
                for(let key in this.editData){
                    if (this.editData[key]){
                        payload[key] = this.editData[key]
                    }
                }
                let result = await this.$network.requestPost("/items/"+this.collectionWithYear, this.editData);
                this.loadPeopleData();
                this.editData = undefined;
                this.expandedRow = -1;
    
                if(result.status === 200){
                    this.snackbar.show = true;
                    this.snackbar.color = "success";
                    this.snackbar.icon = "mdi-plus-thick";
                    this.snackbar.text = "Neuer Datensatz wurde angelegt."
                }
            }
        },

        changeStatusDate(oldStatus, newStatus){
            if(oldStatus != newStatus){
                this.editData.status_aenderung_datum = dayjs();
            }
        },

        async getTotalCosts(){
            if(this.editData.freizeit){
                let camp = await this.$network.getCampData(this.dashboard.activeCampYear);
                let freizeit = await this.$network.requestFreizeit(this.dashboard.activeCampYear, this.editData.freizeit);
                let costs = freizeit.preis;
                if(this.editData.tshirt){
                    costs += camp.tshirt_preis;
                }
                if(this.editData.tassen && this.editData.tassen > 0){
                    costs += camp.tasse_preis * this.editData.tassen;
                }
                return costs
            }else if(this.editData.taetigkeit){
                let camp = await this.$network.getCampData(this.dashboard.activeCampYear);
                let costs = 0
                if(["Beruf","Rentner"].includes(this.editData.taetigkeit)){
                    costs = 40;
                }else if(["Azubi","Schüler","nicht erwerbstätig"].includes(this.editData.taetigkeit)){
                    costs = 30;
                }else if(["Bibelschüler"].includes(this.editData.taetigkeit)){
                    costs = 0;
                }else{
                    costs = 40;
                }
                if(this.editData.tassen && this.editData.tassen > 0){
                    costs += camp.tasse_preis * this.editData.tassen;
                }
                return costs;
            }
            return 0
        },

        async saveEdit(){
            // check if status changed
            let result = await this.$network.requestGet("/items/"+this.collectionWithYear+"/"+this.editedId);
            if(result.status === 200){
                this.changeStatusDate(result.data.data.status_person, this.editData.status_person);
                
                const totalCost = await this.getTotalCosts();

                if(this.editData.kosten_gesamt) {
                    this.editData.kosten_gesamt = totalCost;
                    this.setPayedStatusKostenGesamt();
                }
                if(this.editData.kosten_buchungen) {
                    this.editData.kosten_buchungen.total = totalCost;
                    this.setPayedStatusKostenBuchungen();
                }
            }
            await this.$network.requestPatch("/items/"+this.collectionWithYear+"/"+this.editedId, this.cleanedEditData);
            if(this.year){
                let campResult = await this.$network.requestGet("/items/camp?filter[jahr]="+this.year+"&fields=freizeit.datum_bis")
                let camp = campResult.data.data[0];
                await this.$network.peopleManagerPostSaveOperations(this.collectionWithYear, camp, this.cleanedEditData);
            }
            this.loadPeopleData();
            this.editData = undefined;
            this.editedId = -1;
            this.expandedRow = -1;
            this.snackbar.show = true;
            this.snackbar.color = "success";
            this.snackbar.icon = "mdi-check-bold";
            this.snackbar.text = "Daten wurden gespeichert."
            this.$router.push({path:this.$router.currentRoute.path, query:{}});
        },

        cancelEdit(){
            this.editedId = undefined;
            this.editData = undefined;
            this.expandedRow = -1;
            this.snackbar.show = true;
            this.snackbar.color = "grey";
            this.snackbar.icon = "mdi-close-thick";
            this.snackbar.text = "Bearbeitung wurde abgebrochen."
            this.$router.push({path:this.$router.currentRoute.path, query:{}});
        },

        setPayedStatusKostenGesamt(){
            let costsTotal = parseInt(this.editData.kosten_gesamt);
            let costsDiscount = parseInt(this.editData.kosten_zuschuss);
            let costsPayed = parseInt(this.editData.kosten_bezahlt);
            if(costsPayed === 0 && costsDiscount === 0 && costsTotal > 0){
                this.editData.status_bezahlt = "Nicht bezahlt";
            }else{
                if((costsPayed + costsDiscount) === costsTotal ){
                    this.editData.status_bezahlt = "Bezahlt"; 
                }else if((costsPayed + costsDiscount) > costsTotal){
                    this.editData.status_bezahlt = "Bezahlt zuviel";
                }else{
                    this.editData.status_bezahlt = "Bezahlt abweichend"; 
                }
            }
        },

        setPayedStatusKostenBuchungen(){
            let costsTotal = parseInt(this.editData.kosten_buchungen.total);
            let costsPayed = 0;
            for(let payment of this.editData.kosten_buchungen.payments){
                if(payment.type.operation === "ADD"){
                    costsPayed += parseInt(payment.amount);
                }else if(payment.type.operation === "SUBTRACT"){
                    costsPayed -= parseInt(payment.amount);
                }
            }
            if(costsPayed === 0 && costsTotal > 0){
                this.editData.status_bezahlt = "Nicht bezahlt";
            }else{
                if(costsPayed === costsTotal ){
                    this.editData.status_bezahlt = "Bezahlt"; 
                }else if(costsPayed > costsTotal){
                    this.editData.status_bezahlt = "Bezahlt zuviel";
                }else{
                    this.editData.status_bezahlt = "Bezahlt abweichend"; 
                }
            }
        }
    }
    
}
</script>